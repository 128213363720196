<script lang="ts" setup>
import { useUniversalPixel } from "~/composables/universalPixel";

useUniversalPixel().init();
</script>

<template>
    <div>
        <HeaderContent ref="header" />
        <main ref="mainContent" class="relative container min-h-screen pt-40 md:pt-44 lg:pt-48 print:pt-10">
            <slot />
        </main>
        <FooterContent />
    </div>
</template>
